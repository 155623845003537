<template>
  <div class="grey darken-4">
    <BannerHeader
      :title="title"
      subtitle="The Environmentally Safe Solution"
      uuid="88160c0d-e3bb-415e-960f-a87801dbc921"
    />
    <ServicesContent
      bulletsHeading="Washout Services"
      class="mt-12"
      :images="images"
      :title="title"
    >
      <v-img
        src="/img/ConcreteWashoutSystems@2x.png"
        alt="Concrete Washout Systems Logo - C&C Industrial"
        contain
        class="mb-4"
      />
      <div class="grey--text text--lighten-2">

        <p>CWS bins provide full environmental compliance for all jobsite concrete waste needs. Bins provide a quick and headache free way to contain and dispose of concrete waste on a jobsite.</p>
        <p>Our CWS bins are mobile and easy to move, making them ideal for a tight jobsite.</p>
        <p>We service the Eastern NC, Southern VA, and Triangle RDU area, providing services for both commercial and residential sites.</p>
        <p>Call <a href="tel:1-888-828-1788">(888) 828-1788</a> to learn more about our long and short term availbility of CWS bins for your jobsite.</p>
      </div>
      <template v-slot:sidebar>
        <h3
          id="expertise-list"
          class="text-h5 primary--text"
        >Washout Services</h3>
        <v-list
          class="transparent"
          dense
        >
          <template v-for="(i, idx) in bullets">
            <v-list-item
              :key="idx"
              class="text-overline"
              :href="i.url ? i.url : ''"
              :target="i.targetBlank ? '_blank' : ''"
            >
              <span class="grey--text text--lighten-2">
                {{ i.value }}
              </span>
            </v-list-item>
            <v-divider
              v-if="idx < bullets.length - 1"
              :key="`${idx}-divider`"
              class="grey darken-3"
            />
          </template>
        </v-list>
        <h3 class="text-h5 primary--text">Environmental & Technical Data</h3>
        <v-list
          color="transparent"
          dense
        >
          <template v-for="(i, idx) in resources">
            <v-hover
              v-slot="{ hover }"
              :key="idx"
            >
              <v-list-item
                :key="idx"
                :class="`text-overline ${hover && i.url ? 'primary cursor' : ''}`"
                :href="i.url ? i.url : ''"
                :target="i.targetBlank ? '_blank' : ''"
              >
                <v-icon
                  v-if="i.icon"
                  :color="hover ? 'white' : i.iconColor ? i.iconColor : ''"
                  left
                >
                  {{ i.icon }}
                </v-icon>
                <span :class="hover && i.url ? 'white--text' :'grey--text text--lighten-2'">
                  {{ i.value }}
                </span>
              </v-list-item>
            </v-hover>
            <v-divider
              v-if="idx < bullets.length - 1"
              :key="`${idx}-divider`"
              class="grey darken-3"
            />
          </template>
        </v-list>
      </template>
    </ServicesContent>
    <v-divider />
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ServicesContent from '@/modules/services/components/ServicesContent'
export default {
  metaInfo: {
    title: 'Concrete Washout Services',
    description: 'Concrete Washout Systems (CWS) bins provide full environmental compliance for all jobsite concrete waste needs. '
  },
  components: {
    BannerHeader,
    ServicesContent

  },
  data () {
    return {
      title: 'Concrete Washout Services',
      bullets: [
        {
          value: 'Onsite concrete waste managment'
        },
        {
          value: 'Environmental compliance'
        },
        {
          value: 'Easy to move bins'
        },
        {
          value: 'Commercial or residential jobsites'
        }
      ],
      resources: [
        {
          value: 'CWS Overview',
          url: 'https://ucarecdn.com/97de4667-8902-4a8b-8b2b-cfd978ac0ee7/ConcreteWashoutSystemsCustomerHandout.pdf',
          targetBlank: true,
          icon: 'fa-regular fa-file-pdf',
          iconColor: 'primary'
        }
      ],
      images: [
        {
          uuid: 'e8cfd71d-e944-4948-9fba-909bb95cd9fb',
          alt: 'CWS - Washout Service Onsite'
        },
        {
          uuid: '88160c0d-e3bb-415e-960f-a87801dbc921',
          alt: 'CWS - Washout Service Onsite'
        },
        {
          uuid: 'b50be5d7-d699-4ec5-8a7a-46036d132b60',
          alt: 'CWS - Washout Service Onsite'
        },
        {
          uuid: 'e22d937d-0449-41fe-bc5c-346111e982ef',
          alt: 'CWS - Washout Service Onsite'
        },
        {
          uuid: 'd6393a38-775d-4486-be49-e8bb81760d9b',
          alt: 'CWS Truck & Water Pump'
        },
        {
          uuid: '1a3821a2-f06d-46e2-8268-59d08ae9830f',
          alt: 'Ready Mix Truck Washing Out'
        },
        {
          uuid: '7a41281a-9c2d-4ac6-849e-9098df6ad110',
          alt: 'Unloading Bin'
        }
      ]
    }
  }
}
</script>
